import React, { useEffect, useRef, useState } from "react";
import Popover from "@mui/material/Popover";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

import useAddress from "./hooks/useAddressFilter";
import styles from "./chatbot.module.css";
import { emoji, sendAction } from "../../constants/images";

const ChatbotMessageSend = ({ messages, chatId, isBotWorking, chatClose, onClose, handleEmojiSelect,
  onChatMsgSubmit, handleRating, handleSendDailogApi, createChat, isAddress, stopSendloading, apiBaseUrl, createType }) => {

  // State and variables initialization
  const [msg, setMsg] = useState(""); // State for message input

  const { onChangeAddress, placesData, showList, setShowList, handleSaveAddresswithpostCode } = useAddress(apiBaseUrl); // Using custom address hook
  const isFormAlreadySubmitted = JSON.parse(localStorage.getItem(chatId)); // Checking if form is already submitted
  const textareaRef = useRef(); // Ref for message input
  const [anchorEl, setAnchorEl] = React.useState(null); // State for popover anchor
  const [postCode, setPostCode] = useState("")

  // Function to handle click event on emoji icon
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function to handle close event for popover
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Variables for popover
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // Function to handle key down event
  function handleKeyDown(e) {
    if (
      e.key === "Enter" && // Check if Enter key is pressed
      isBotWorking && // Check if bot is working
      msg && // Check if message is not empty
      messages?.[messages.length - 1]?.field !== "dailog_select" && // Check if last message is not a dialog select
      !stopSendloading && // Check if sending is not stopped
      (createType !== "arrange-callback" && createType !== "property-viewing" && createType !== "schedule-valuation") // Check if not one of these types
    ) {

      if (chatClose) { // Check if chat is closing
        handleRating(msg, setMsg, onClose, isFormAlreadySubmitted, chatId); // Handle rating
      } else {

        if (!createChat) {
          handleSendDailogApi(msg + (postCode ? (", " + postCode) : ""), chatId); // Send message using API
          setMsg(""); // Clear message input
        } else {
          onChatMsgSubmit(e, msg, chatId, isFormAlreadySubmitted, setMsg); // Submit chat message
        }
      }
    }
  }

  // JSX for the component
  return (
    <div className={styles.all_chatpart_footer + " d-flex"}>
      <img src={emoji} alt="" onClick={handleClick} /> {/* Emoji icon */}
      <Popover
        id={id} open={open} anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{ zIndex: "9999999999" }}
      >
        {/* Emoji picker */}
        <Picker
          data={data}
          onEmojiSelect={(emoji) => handleEmojiSelect(emoji, textareaRef, setMsg, handleClose)}
          theme="light" emojiSize={18} previewPosition="none"
        />
      </Popover>

      {isAddress ? ( // If address is needed
        <Autocomplete
          disablePortal open={showList && msg?.length > 2}
          id="select-on-focus"
          selectOnFocus
          disableClearable
          inputValue={msg}
          filterOptions={(options, state) => options}
          onChange={(event, newValue) => {
            handleSaveAddresswithpostCode(newValue, setMsg)
            setShowList(false);
          }}
          options={placesData} sx={{ width: 300, color: "#464749", p: 0, m: 0 }}
          getOptionLabel={(option) => option?.description}
          getOptionSelected={(option, value) => option?.description === value?.description}
          renderOption={(props, option) => (
            <Box {...props} sx={{ padding: "0", margin: "0" }}>
              <span className={styles.placeList} style={{ padding: "0", margin: "0", width: "100%", borderBottom: "none", }} >
                {option.description}
              </span>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              value={msg}
              {...params}
              className={styles.address_input}
              placeholder="Search address ..."
              autoFocus={true}
              onChange={(e) => {
                setMsg(e.target.value);
                if (isAddress && e.target.value.length > 2) {
                  onChangeAddress(e);
                } else { setShowList(false); }
              }}
              onKeyPress={(e) => { handleKeyDown(e); }}
            />

          )}
        />
      ) : ( // If normal text input is needed
        <input type="text" ref={textareaRef} name=""
          id="" placeholder="Enter your message.." value={msg}
          onChange={(e) => { setMsg(e.target.value); }}
          onKeyPress={(e) => { handleKeyDown(e); }}
        />
      )}
      {/* Send button */}
      <div
        onClick={(e) => {
          if (
            isBotWorking && // Check if bot is working
            msg && // Check if message is not empty
            !stopSendloading && // Check if sending is not stopped
            messages?.[messages.length - 1]?.field !== "dailog_select" && // Check if last message is not a dialog select
            (createType !== "arrange-callback" && createType !== "property-viewing" && createType !== "schedule-valuation") // Check if not one of these types
          ) {
            if (chatClose) { // Check if chat is closing
              handleRating(msg, setMsg, onClose, isFormAlreadySubmitted, chatId); // Handle rating
            } else {
              if (!createChat) {
                handleSendDailogApi(msg + (postCode ? (", " + postCode) : ""), chatId); // Send message using API
                setMsg(""); // Clear message input
              } else {
                onChatMsgSubmit(e, msg, chatId, isFormAlreadySubmitted, setMsg); // Submit chat message
              }
            }
          }
        }}
      >
        <img className={styles.send_icon} src={sendAction} alt="" /> {/* Send icon */}
      </div>
    </div>
  );
};

export default ChatbotMessageSend; // Exporting component
// default
export const chatImg = "https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/ChatIcon.svg";
export const liveIcon = "https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/liveDot.svg";
export const logo = "https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/propertyjinni-favicon.svg";
export const sendIcon = "https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/sendechatIcon.svg";
export const sendIconBtc = "https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/sendechatDefaultt.svg";
export const emoji = "https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/Vector.svg";
export const sendAction = "https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/sendIcon.svg";
export const wave = "https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/wave_icon.png";

// btc
export const option = "https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/chatOption.svg";
export const drop = "https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/chatDrop.svg";
export const shape = "https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/chatShape.svg";
export const sendimgbtc = "https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/sendechatIconbtc.svg";
export const emojibtc ="https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/chatEmoji.svg";
export const sendIconbtc ="https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/chatSend.svg";
export const chatImgbtc = "https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/agent.png";
export const chatArrow = "https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/chatArrow.svg";

// daniels
export const chatImgdaniel ="https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/danielsIcon.svg";
export const chatCanceldaniel = "https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/danielsCancel.svg";
export const imgdaniels = "https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/sendChat.svg";
export const emojidaniels = "https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/danielsEmoji.svg";
export const sendIcondaniels = "https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/danielsSend.svg";
export const chatdaniels = "https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/danielsChat.png";

// denhan
export const chatImgdenhan ="https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/denhanIcon.svg";
export const chatCancel = "https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/denhanCancel.svg";
export const sendIcondenhan = "https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/denhanSend.png";
export const chatdenhan = "https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/denhanChat.svg";

// magic brick

export const chatImgmagicbrick = "https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/sendechatDefaultt.svg";
export const chatmagicbrick = "https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/girl_chat.png";
export const sendIconmagicbrick = "https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/sendIconM.svg";

// lundiniom

export const chatIconlundiniom = "https://crm-jinni-bot-storage.s3.eu-west-2.amazonaws.com/chatbot_public/chaticonlundiniom.svg";
import React, { useRef, useState } from "react";
import Popover from "@mui/material/Popover";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

import styles from "./chatbot.module.css";
import useAddress from "../Chatbot/hooks/useAddressFilter.js";
import { sendIconbtc, emojibtc } from "../../constants/images.js";

const ChatbotMessageSend = ({ messages, chatId, isBotWorking, chatClose, onClose, handleEmojiSelect,
  onChatMsgSubmit, handleRating, handleSendDailogApi, createChat, isAddress, stopSendloading, apiBaseUrl, createType }) => {

  const [msg, setMsg] = useState("");
  const { onChangeAddress, placesData, showList, setShowList ,handleSaveAddresswithpostCode } = useAddress(apiBaseUrl);
  const isFormAlreadySubmitted = JSON.parse(localStorage.getItem(chatId));
  const textareaRef = useRef();
  const [anchorEl, setAnchorEl] = React.useState(null);

  // Function to handle click on emoji icon
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function to handle closing emoji picker popover
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // Function to handle key down event
  function handleKeyDown(e) {
    // Check if Enter key is pressed and conditions are met to send message
    if (
      e.key === "Enter" &&
      isBotWorking &&
      msg &&
      messages?.[messages.length - 1]?.field !== "dailog_select" &&
      !stopSendloading &&
      (createType !== "arrange-callback" && createType !== "property-viewing" && createType !== "schedule-valuation")
    ) {
      // Check if chat is closed for rating or sending normal message
      if (chatClose) {
        handleRating(msg, setMsg, onClose, isFormAlreadySubmitted, chatId);
      } else {
        if (!createChat) {
          handleSendDailogApi(msg, chatId);
          setMsg("");
        } else {
          onChatMsgSubmit(e, msg, chatId, isFormAlreadySubmitted, setMsg);
        }
      }
    }
  }

  return (
    <div className={styles.chatbot_pill}>
      <div className={styles.all_chatpart_footer + " d-flex"} >
        {/* Emoji picker */}
        <img src={emojibtc} alt="" onClick={handleClick} />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{ zIndex: "9999999999" }}
        >
          <Picker
            data={data}
            onEmojiSelect={(emoji) =>
              handleEmojiSelect(emoji, textareaRef, setMsg, handleClose)
            }
            theme="light"
            emojiSize={18}
            previewPosition="none"
          />
        </Popover>

        {/* Autocomplete for address or regular text input */}
        {isAddress ? (
          <Autocomplete
            disablePortal
            open={showList && msg?.length > 2}
            id="select-on-focus"
            selectOnFocus
            disableClearable
            inputValue={msg}
            filterOptions={(options, state) => options}
            onChange={(event, newValue) => {
              handleSaveAddresswithpostCode(newValue, setMsg)
              setShowList(false);
            }}
            options={placesData}
            sx={{ width: 300, color: "#464749", p: 0, m: 0 }}
            getOptionLabel={(option) => option?.description}
            getOptionSelected={(option, value) =>
              option?.description === value?.description
            }
            renderOption={(props, option) => (
              <Box {...props} sx={{ padding: "0", margin: "0" }}>
                <span
                  className={styles.placeList}
                  style={{ padding: "0", margin: "0", width: "100%", borderBottom: "none", }}
                >
                  {option.description}
                </span>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                className={styles.address_input}
                placeholder="Search address ..."
                autoFocus={true}
                value={msg}
                onChange={(e) => {
                  setMsg(e.target.value);

                  if (isAddress && e.target.value.length > 2) {
                    onChangeAddress(e);
                  } else {
                    setShowList(false);
                  }
                }}
                onKeyPress={(e) => {
                  handleKeyDown(e);
                }}
              />
            )}
          />
        ) : (
          <input
            type="text"
            ref={textareaRef}
            name=""
            id=""
            placeholder="Enter your message.."
            value={msg}
            onChange={(e) => { setMsg(e.target.value); }}
            onKeyPress={(e) => {
              handleKeyDown(e);
            }}
          />
        )}
        {/* Send button */}
        <div
          onClick={(e) => {

            if (
              isBotWorking && msg && !stopSendloading &&
              messages?.[messages.length - 1]?.field !== "dailog_select" &&
              (createType !== "arrange-callback" && createType !== "property-viewing" && createType !== "schedule-valuation")
            ) {

              if (chatClose) {
                handleRating(msg, setMsg, onClose, isFormAlreadySubmitted, chatId);
              } else {
                if (!createChat) {
                  handleSendDailogApi(msg, chatId);
                  setMsg("");
                } else {
                  onChatMsgSubmit(e, msg, chatId, isFormAlreadySubmitted, setMsg);
                }
              }
            }
          }}
        >
          <img className={styles.send_msg} src={sendIconbtc} alt="" />
        </div>
      </div>
    </div>
  );
};

export default ChatbotMessageSend;

import React, { useRef, useState } from "react";
import Popover from "@mui/material/Popover";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

import styles from "./chatbot.module.css";
import useAddress from "../Chatbot/hooks/useAddressFilter.js";
import { sendIcondenhan, emoji } from "../../constants/images.js";

// ChatbotMessageSend component for sending messages in chat
const ChatbotMessageSend = ({ messages, chatId, isBotWorking, chatClose, onClose, handleEmojiSelect,
  onChatMsgSubmit, handleRating, handleSendDailogApi, createChat, isAddress, stopSendloading, apiBaseUrl, createType }) => {

  const [msg, setMsg] = useState(""); // State for storing message input
  const { onChangeAddress, placesData, showList, setShowList ,handleSaveAddresswithpostCode } = useAddress(apiBaseUrl); // Address hook
  const isFormAlreadySubmitted = JSON.parse(localStorage.getItem(chatId)); // Check if form already submitted
  const textareaRef = useRef(); // Reference to textarea input

  const [anchorEl, setAnchorEl] = React.useState(null); // State for Popover anchor
  const handleClick = (event) => { setAnchorEl(event.currentTarget); }; // Handle click to open Popover
  const handleClose = () => { setAnchorEl(null); }; // Handle close of Popover

  const open = Boolean(anchorEl); // Check if Popover is open
  const id = open ? "simple-popover" : undefined; // Popover id

  // Handle key down event (e.g., Enter key pressed)
  function handleKeyDown(e) {
    if (e.key === "Enter" && isBotWorking && msg && messages?.[messages.length - 1]?.field !== "dailog_select" && !stopSendloading &&
      (createType !== "arrange-callback" && createType !== "property-viewing" && createType !== "schedule-valuation")) {

      if (chatClose) {
        handleRating(msg, setMsg, onClose, isFormAlreadySubmitted, chatId); // Handle rating if chat is closing
      } else {
        if (!createChat) {
          handleSendDailogApi(msg, chatId); // Send message via API if not creating chat
          setMsg(""); // Clear message input
        } else {
          onChatMsgSubmit(e, msg, chatId, isFormAlreadySubmitted, setMsg); // Submit chat message
        }
      }
    }
  }

  return (
    <div className={styles.chatbot_pill}>
      <div className={styles.all_chatpart_footer + " d-flex"} >
        <img src={emoji} alt="" onClick={handleClick} /> {/* Emoji icon to open Emoji Picker */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "left", }}
          transformOrigin={{ vertical: "top", horizontal: "right", }}
          sx={{ zIndex: "9999999999" }}
        >
          <Picker
            data={data}
            onEmojiSelect={(emoji) => handleEmojiSelect(emoji, textareaRef, setMsg, handleClose)}
            theme="light"
            emojiSize={18}
            previewPosition="none"
          />
        </Popover>

        {isAddress ? ( // Render Autocomplete for address if needed
          <Autocomplete
            disablePortal
            open={showList && msg?.length > 2}
            id="select-on-focus"
            selectOnFocus
            disableClearable
            inputValue={msg}
            filterOptions={(options, state) => options}
            onChange={(event, newValue) => {
              handleSaveAddresswithpostCode(newValue, setMsg)
              setShowList(false);
            }}
            options={placesData}
            sx={{ width: 300, color: "#464749", p: 0, m: 0 }}
            getOptionLabel={(option) => option?.description}
            getOptionSelected={(option, value) =>
              option?.description === value?.description
            }
            renderOption={(props, option) => (
              <Box {...props} sx={{ padding: "0", margin: "0" }}>
                <span
                  className={styles.placeList}
                  style={{ padding: "0", margin: "0", width: "100%", borderBottom: "none", }}
                >
                  {option.description}
                </span>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                className={styles.address_input}
                placeholder="Search address ..."
                autoFocus={true}
                value={msg}
                onChange={(e) => {
                  setMsg(e.target.value);
                  
                  if (isAddress && e.target.value.length > 2) {
                    onChangeAddress(e);
                  } else {
                    setShowList(false);
                  }
                }}
                onKeyPress={(e) => { handleKeyDown(e) }}
              />
            )}
          />
        ) : (
          <input
            type="text"
            ref={textareaRef}
            name=""
            id=""
            placeholder="Enter your message.."
            value={msg}
            onChange={(e) => { setMsg(e.target.value); }}
            onKeyPress={(e) => { handleKeyDown(e); }}
          />
        )}
        {/* Send message button */}
        <div className={styles.send_msg}
          onClick={(e) => {
            if (
              isBotWorking && msg &&
              !stopSendloading &&
              messages?.[messages.length - 1]?.field !== "dailog_select" &&
              (createType !== "arrange-callback" && createType !== "property-viewing" && createType !== "schedule-valuation")
            ) {
              if (chatClose) {
                handleRating(msg, setMsg, onClose, isFormAlreadySubmitted, chatId);
              } else {
                if (!createChat) {
                  handleSendDailogApi(msg, chatId);
                  setMsg("");
                } else {
                  onChatMsgSubmit(e, msg, chatId, isFormAlreadySubmitted, setMsg);
                }
              }
            }
          }}
        >
          <img className={styles.send_icon} src={sendIcondenhan} alt="" /> {/* Send icon */}
        </div>
      </div>
    </div>
  );
};

export default ChatbotMessageSend;

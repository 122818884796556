import { useCallback, useState } from "react";
import Axios from "../../../utils/axios";
import { debounce } from "../../../utils/commonHelp";

const useAddress = (apiBaseUrl) => {
  // Custom hook to manage address input and suggestions
  const [placesData, setPlacesData] = useState([]);
  const [showList, setShowList] = useState(false);
  const [error, setError] = useState("");

  // Function to fetch the list of places based on the input text
  const fetchPlacesList = (place) => {
    if (place === "") {
      // If input is empty, hide the list and clear data
      setShowList(false);
      setPlacesData([]);
      return;
    }
    Axios.get(`${apiBaseUrl}/places/address-autocomplete?text=${place}`)
      .then((res) => {
        // Set the places data and show the list on successful response
        setPlacesData(res?.data?.data);
        setShowList(true);
      })
      .catch((err) => {
        // Handle error by clearing data and setting error state
        setPlacesData([]);
        setError(err);
      });
  };

  // Function to handle click event on a location from the list
  const handleLocationClick = (location, setMsg) => {
    setMsg(location.description); // Set the selected location's description
    setPlacesData([]); // Clear the places data
    setShowList(false); // Hide the list
  };

  // Creating a debounced version of fetchPlacesList to limit API calls
  const userTxtChangeDebounce = useCallback(
    debounce((txtval) => fetchPlacesList(txtval), 800), []
  );

  // Handler for the input change event
  const onChangeAddress = (e) => {
    userTxtChangeDebounce(e.target.value); // Call the debounced function with the input value
  };

  const handleSaveAddresswithpostCode = (address, setMsg) => {
    Axios.get(`${apiBaseUrl}/places/place-detail-by-address-id?place_id=${address?.place_id}`)
      .then((res) => {
        if (res?.data?.data?.address?.zip) {
          setMsg((prev) => address?.description + ", " + res?.data?.data?.address?.zip)
        }
        else {
          setMsg((prev) => address?.description)
        }
      })
      .catch((err) => {
        console.log("No address found.");
      });
  }

  // Return necessary functions and states from the custom hook
  return {
    fetchPlacesList,
    onChangeAddress,
    handleLocationClick,
    placesData,
    showList,
    error,
    setShowList,
    handleSaveAddresswithpostCode
  };
};

export default useAddress;

import React, { useEffect, useState } from "react";

import Axios from "./utils/axios";
import { getMainApiHost } from "./constants/constants";
import ChatBotIndex from "./components/Chatbot/index";
import ChatBotDenhanIndex from "./components/ChatbotDenhan/index";
import ChatBotBtcIndex from "./components/ChatbotBtc/index";
import ChatBotDanielsIndex from "./components/ChatbotDaniels/index";
import ChatBotMagicBrickIndex from "./components/ChatbotMagicBrick/index";
import ChatBotLundiniomIndex from "./components/ChatbotLundiniom/index";
import useSocket from "./components/hooks/useSocket";
import styles from "./index.module.css";
import useChatMessages from "./components/Chatbot/hooks/useChatMessages";

const App = ({ bot_id, userid, user_image, template, app_host }) => {

  // Extracting API host and base host from the provided app_host
  const [apiHost, apiBaseHost] = getMainApiHost(app_host)
  // Custom hook to manage WebSocket connection
  const { connectSocket, newSocketMessage, isChatClose, setChatClose, setSocketNewMessage } = useSocket(apiHost);
  // State to manage the visibility of chat icon
  const [isIconsetVisible, setIconsetVisible] = useState(false);
  const [chatId2, setChatId2] = useState("");
  // Custom hook to manage chat messages
  const { waitingTime, getChatbotData, isChatbotActive } = useChatMessages(1, apiBaseHost);

  // fetch chat bot key from useName
  const getKeyFromUserName = () => {
    Axios.get(`${apiBaseHost}/domainKey/viewKeyByUserName?userName=${userid}`)
      .then((res) => { setChatId2(res.data.data); })
      .catch((error) => { setChatId2("Nan"); });
  };

  // Fetch chat ID based on user ID
  useEffect(() => {
    if (userid) {
      getKeyFromUserName()
    } else { setChatId2(bot_id); }
  }, [bot_id]);

  // Connect to WebSocket when chat ID is available
  const connectToSocket = (chatBotConnectid) => {
    connectSocket(chatBotConnectid, () => { });
  };

  // Clear socket state
  const clearSocketState = () => {
    setChatClose(false);
    setSocketNewMessage(null);
  };

  // Fetch chatbot data when chat ID changes
  useEffect(() => {
    if (chatId2) {
      getChatbotData(chatId2)
    }
  }, [chatId2])

  //  Set timeout for showing chat icon based on waiting time
  useEffect(() => {
    if (waitingTime > 0) {
      const timer = setTimeout(() => {
        setIconsetVisible(true);
      }, waitingTime * 1000);

      // Clear timeout if component unmounts or waitingTime changes
      return () => clearTimeout(timer);
    }
  }, [waitingTime]);

  return (
    <>
      {/* Render chatbot components based on template type */}
      {isChatbotActive &&
        <div className={styles.jinniBotBody}>
          {template === 'denhan' && <ChatBotDenhanIndex
            chatId2={chatId2}
            userid={userid}
            userImage={user_image}
            connectToSocket={connectToSocket}
            socketNewMessage={newSocketMessage}
            isChatClose={isChatClose}
            clearSocketState={clearSocketState}
            template={template}
            apiBaseUrl={apiBaseHost}
            isIconsetVisible={isIconsetVisible}
            setIconsetVisible={setIconsetVisible}
          />}
          {template === 'btc' && <ChatBotBtcIndex
            chatId2={chatId2}
            userid={userid}
            userImage={user_image}
            connectToSocket={connectToSocket}
            socketNewMessage={newSocketMessage}
            isChatClose={isChatClose}
            clearSocketState={clearSocketState}
            template={template}
            apiBaseUrl={apiBaseHost}
            isIconsetVisible={isIconsetVisible}
            setIconsetVisible={setIconsetVisible}
          />}
          {template === 'daniels' && <ChatBotDanielsIndex
            chatId2={chatId2}
            userid={userid}
            userImage={user_image}
            connectToSocket={connectToSocket}
            socketNewMessage={newSocketMessage}
            isChatClose={isChatClose}
            clearSocketState={clearSocketState}
            template={template}
            apiBaseUrl={apiBaseHost}
            isIconsetVisible={isIconsetVisible}
            setIconsetVisible={setIconsetVisible}
          />}
          {template === 'magicbrick' && <ChatBotMagicBrickIndex
            chatId2={chatId2}
            userid={userid}
            userImage={user_image}
            connectToSocket={connectToSocket}
            socketNewMessage={newSocketMessage}
            isChatClose={isChatClose}
            clearSocketState={clearSocketState}
            template={template}
            apiBaseUrl={apiBaseHost}
            isIconsetVisible={isIconsetVisible}
            setIconsetVisible={setIconsetVisible}
          />}
          {template === 'lundiniom' && <ChatBotLundiniomIndex
            chatId2={chatId2}
            userid={userid}
            userImage={user_image}
            connectToSocket={connectToSocket}
            socketNewMessage={newSocketMessage}
            isChatClose={isChatClose}
            clearSocketState={clearSocketState}
            template={template}
            apiBaseUrl={apiBaseHost}
            isIconsetVisible={isIconsetVisible}
            setIconsetVisible={setIconsetVisible}
          />}
          {template !== 'denhan' && template !== 'btc' && template !== 'daniels' && template !== 'magicbrick' && template !== 'lundiniom' && <ChatBotIndex
            chatId2={chatId2}
            userid={userid}
            userImage={user_image}
            connectToSocket={connectToSocket}
            socketNewMessage={newSocketMessage}
            isChatClose={isChatClose}
            clearSocketState={clearSocketState}
            apiBaseUrl={apiBaseHost}
            isIconsetVisible={isIconsetVisible}
            setIconsetVisible={setIconsetVisible}
          />}
        </div>}
    </>
  );
};

export default App;

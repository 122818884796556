import React, { useEffect, useRef, useState } from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

import styles from "./chatbot.module.css";
import BotCal from "../custom/BotCal.jsx";
import { sendIconBtc } from "../../constants/images.js";

const ChatbotMessages = ({ chatId, messages, chatLoading, handleSendDailogApi, createChat, onChatMsgSubmit,
  createType, chatBotAdminData, stopSendloading, chatClose, onClose, handleRating, apiBaseUrl, template }) => {

  // Retrieve form submission status from local storage
  const isFormAlreadySubmitted = JSON.parse(localStorage.getItem(chatId));
  // Reference for the chat container
  const chatContainerRef = useRef(null);
  // State to manage the current message
  const [msg, setMsg] = useState("");

  // Automatically scroll to the latest message when new messages are added
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className={styles.all_chatpart_body} ref={chatContainerRef}>
      {/* Map through the messages array and render each message */}
      {messages?.map((message, index) => {
        // Determine if the message is from the user
        const isUserMessage =  (message?.sender === isFormAlreadySubmitted?._id || message?.sender == null) && (!message?.isBot);
        return (
          <>
            {/* Render sender information */}
            <div className={isUserMessage || message?.sender === "you" ? `${styles.sender_box} ${styles.sender_right_side}` : styles.sender_box}>
              {isUserMessage || message?.sender === "you" ? (
                <>  You  <span>  {isFormAlreadySubmitted?.name?.slice(0, 1) || "y"}  </span></>
              ) : (
                <>
                  <img src={(message?.isBot ? chatBotAdminData?.chatBotData?.chatbotImage : message?.sender?.avatar || chatBotAdminData?.chatBotData?.chatbotImage) || sendIconBtc} alt="" />
                  {(message?.isBot ? chatBotAdminData?.chatBotData?.chatbotName : message?.sender?.name || chatBotAdminData?.chatBotData?.chatbotName) || "Bot"}
                </>
              )}
            </div>
            {/* Render message content */}
            <div key={message?._id} className={isUserMessage || message?.sender === "you" ? `${styles.box} ${styles.right_side}` : styles.box}>
              <div dangerouslySetInnerHTML={{ __html: message?.message ? message?.message?.replace(/\n/g, " <br/> ") : "" }}></div>
            </div>
            {/* Render message options if available */}
            {message?.field === "dailog_select" && message?.value !== "" && (
              <Stack direction="row" sx={{ flexWrap: "wrap" }} spacing={2} className={styles.sender_right_side}>
                {message.options?.map((data, index) => {
                  return (
                    <span style={{ margin: "3px 2px" }} className={message.value === data ? styles.slectedChip : styles.sender_chip}>
                      <Chip label={data} variant={message.value === data ? "filled" : "outlined"} />
                    </span>
                  );
                })}
              </Stack>
            )}
          </>
        );
      })}
      {/* Render message options for the last message if available */}
      {messages?.[messages.length - 1]?.field === "dailog_select" && messages?.[messages.length - 1]?.value === "" && (
        <Stack direction="row" sx={{ flexWrap: "wrap" }} spacing={2} className={styles.sender_right_side}>
          {messages?.[messages.length - 1].options?.map((data, index) => {
            return (
              <span style={{ margin: "3px 2px" }} className={styles.sender_chip}>
                <Chip
                  label={data}
                  variant={messages?.[messages.length - 1].value === data ? "" : "outlined"}
                  onClick={(e) => {
                    if (chatClose) {
                      handleRating(data, setMsg, onClose, isFormAlreadySubmitted, chatId);
                    } else {
                      if (!createChat) {
                        handleSendDailogApi(data, chatId);
                      } else {
                        onChatMsgSubmit(e, data, chatId, isFormAlreadySubmitted, setMsg);
                      }
                    }
                  }}
                />
              </span>
            );
          })}
        </Stack>
      )}
      {/* Render calendar component if needed */}
      {(createType === "arrange-callback" || createType === "property-viewing" || createType === "schedule-valuation") && (
        <div className={styles.sender_right_side} style={{ display: "flex" }}>
          <BotCal
            handleSendDailogApi={handleSendDailogApi}
            chatId={chatId}
            chatBotAdminData={chatBotAdminData}
            stopSendloading={stopSendloading}
            messages={messages}
            template={template}
            apiBaseUrl={apiBaseUrl}
          />
        </div>
      )}
      {/* Render typing indicator if chat is loading */}
      {chatLoading && (
        <div className={styles.chat_bubble}>
          <div className={styles.typing}>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatbotMessages;

import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { IoChatbubblesSharp } from "react-icons/io5";

import Chatbot from "./Chatbot";
import styles from "./chatbot.module.css";
import { wave } from "../../constants/images";

const ChatBotIndex = ({ chatId2, userImage, connectToSocket, socketNewMessage, isChatClose, clearSocketState, apiBaseUrl, isIconsetVisible, setIconsetVisible }) => {
  // State for visibility of first message
  const [isFirstMessageVisible, setisFirstMessageVisible] = useState(true);

  // Function to toggle visibility of chat icon set
  const toggleIconset = () => {
    setIconsetVisible((prev) => !prev);
    clearSocketState(); // Clear socket state
  };

  // JSX for the component
  return (
    <div style={{ zIndex: "999998", position: "fixed" }} >
      <div className={styles.full_chatbot}>
        <span className="online_span"></span>
        {/* Rendering Chatbot component if icon set is visible */}
        {isIconsetVisible && (
          <Chatbot
            chatId2={chatId2}
            userImage={userImage}
            onClose={toggleIconset}
            connectToSocket={connectToSocket}
            socketNewMessage={socketNewMessage}
            isChatClose={isChatClose}
            apiBaseUrl={apiBaseUrl}
          />
        )}

        <div className={styles.iconset} >
          {/* Rendering close icon if icon set is visible */}
          {isIconsetVisible && (
            <div onClick={toggleIconset} className={styles.crossico}> <RxCross2 />  </div>
          )}

          {/* Rendering chat icon if icon set is not visible */}
          {!isIconsetVisible && (
            <div className={styles.online_wrapper}>
              <div onClick={toggleIconset} className={styles.icon_wrap}>
                <div className={styles.showicon}>
                  <IoChatbubblesSharp />
                  <span className={styles.online_span}></span>
                </div>
              </div>
              {/* Rendering greeting message if first message is visible */}
              {isFirstMessageVisible && (
                <div className={styles.greering_message}>
                  <img src={wave} />
                  <span>We are online </span>
                  {/* Button to hide the first message */}
                  <div onClick={() => setisFirstMessageVisible(false)} className={styles.online_cancel}>
                    <RxCross2 />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatBotIndex; // Exporting ChatBotIndex component